import Alpine from 'alpinejs';
import MainSidebar from './dashboard/MainSidebar';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import flatpickr from 'flatpickr';
import { Polish } from 'flatpickr/dist/l10n/pl.js';
import { english } from 'flatpickr/dist/l10n/default.js';
import 'flatpickr/dist/flatpickr.min.css';
import FloatingAlert from './dashboard/FloatingAlert';
import BusinessHourSetup from './dashboard/admin/BusinessHourSetup';
import PersonalDataForm from './dashboard/PersonalDataForm';

window.Alpine = Alpine;

Alpine.data('mainSidebar', MainSidebar);
Alpine.data('floatingAlert', FloatingAlert);
Alpine.data('businessHoursSetup', BusinessHourSetup);
Alpine.data('personalDataForm', PersonalDataForm);

Alpine.start();

declare global {
	interface Window {
		Alpine: typeof Alpine;
	}
}

tippy('[data-tippy-content]');

const documentLocale = document.getElementsByTagName('html')[0].getAttribute('lang');

flatpickr('.time-input', {
	enableTime: true,
	noCalendar: true,
	time_24hr: true,
	dateFormat: 'H:i',
	onReady: function () {
		if (this.input.hasAttribute('minDate')) {
			this.set('minDate', Date.parse(this.input.getAttribute('minDate')));
		}
	},
});

flatpickr('.datetime-input', {
	enableTime: true,
	dateFormat: 'Y-m-d H:i',
	time_24hr: true,
	locale: documentLocale === 'pl' ? Polish : english,
	onReady: function () {
		if (this.input.hasAttribute('minDate')) {
			this.set('minDate', Date.parse(this.input.getAttribute('minDate')));
		}
	},
});

flatpickr('.date-input', {
	enableTime: false,
	dateFormat: 'Y-m-d',
	time_24hr: true,
	locale: documentLocale === 'pl' ? Polish : english,
	onReady: function () {
		if (this.input.hasAttribute('minDate')) {
			this.set('minDate', Date.parse(this.input.getAttribute('minDate')));
		}
	},
});

document.addEventListener(
	'DOMContentLoaded',
	function () {
		const allowFamilyMembersCheckbox = <HTMLInputElement>document.getElementById('allow_family_members');
		let enableAlert = 1;

		if (allowFamilyMembersCheckbox && allowFamilyMembersCheckbox.checked) {
			allowFamilyMembersCheckbox.addEventListener('change', function () {
				if (!this.checked && enableAlert) {
					enableAlert = 0;

					alert(
						'Wyłączenie możliwości rejestracji rodzin spowoduje, że wszystkie konta rodzinne przypisane do tej firmy zostaną od niej odłączone. Skutków tej zmiany nie można cofnąć, jeśli nie chcesz tego robić zaznacz z powrotem to pole lub odśwież stronę.',
					);
				}
			});
		}

		const phrasesElement = <HTMLSelectElement>document.getElementById('phrases');

		if (phrasesElement) {
			phrasesElement.addEventListener('change', function (event) {
				if (this.value != '') {
					const report = <HTMLTextAreaElement>document.getElementById('report');
					report.value += this.value + ', ';
				}
				phrasesElement.selectedIndex = 0;
			});
		}

		const videosElement = document.getElementById('videos');

		if (videosElement) {
			if (window.innerWidth > 1024) {
				videosElement.style.maxHeight = videosElement.offsetWidth / 2.34 + 'px';
			} else {
				videosElement.style.maxHeight = (videosElement.offsetWidth / 16) * 9 + 22 + 'px';
			}
		}
	},
	false,
);
