import flatpickr from 'flatpickr';
import { Polish } from 'flatpickr/dist/l10n/pl';
import { english } from 'flatpickr/dist/l10n/default';

export default () => ({
	legalGuardianRequired: false,
	init() {
		let _this = this;
		_this.legalGuardianRequired =
			this.$refs.birthDate.value != '' &&
			new Date(this.$refs.birthDate.value).getTime() > _this.eighteenYearsAgo;

		const documentLocale = document.getElementsByTagName('html')[0].getAttribute('lang');

		flatpickr('#birth_date', {
			enableTime: false,
			dateFormat: 'Y-m-d',
			time_24hr: true,
			locale: documentLocale === 'pl' ? Polish : english,
			onReady: function () {
				if (this.input.hasAttribute('minDate')) {
					this.set('minDate', Date.parse(this.input.getAttribute('minDate')));
				}
			},
			onChange: function () {
				const birthDate = new Date(this.input.value);

				_this.legalGuardianRequired = birthDate.getTime() > _this.eighteenYearsAgo;
			},
		});

		flatpickr('#legal_guardian\\.birth_date', {
			enableTime: false,
			dateFormat: 'Y-m-d',
			time_24hr: true,
			locale: documentLocale === 'pl' ? Polish : english,
			onReady: function () {
				this.set('maxDate', _this.eighteenYearsAgo);
			},
		});
	},
	get eighteenYearsAgo(): number {
		return new Date().setFullYear(new Date().getFullYear() - 18);
	},
});
