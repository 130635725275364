export default () => ({
	visible: true,
	init() {
		this.$refs.progress.style.removeProperty('max-width');

		setTimeout(() => {
			this.close();
		}, 15000);
	},
	close() {
		this.visible = false;

		setTimeout(() => {
			this.$root.remove();
		}, 2500);
	},
});
