export default (values: BusinessHour[]) => ({
	hours: values,
	addPeriod() {
		this.hours.push({});
	},
	removePeriod(index: number) {
		this.hours.splice(index, 1);
	},
});

interface BusinessHour {
	start_at: string;
	end_at: string;
}
